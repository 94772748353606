.card {
	--bs-card-bg: transparent;
	--bs-card-border-radius: 0;
	--bs-card-inner-border-radius: 0;

	border-radius: 0;
    border: none;
	color: var(--cms-card-foreground, var(--cms-foreground));
	background-color: var(--cms-card-background, var(--cms-background));
	height: 100%;

	& .card-img-top {
		object-fit: cover;
	}

	& .card-media {
		overflow: hidden;
		position: relative;
		& .background {
			background-repeat: no-repeat;
			background-position: center top;
			background-size: cover;
			width: 100%;
			transition: transform .5s;
			&:hover {
				transform: scale( var(--cms-transform-thumbnail-scale) );
			}
		}

		& .details {
			z-index: 5;
			position: absolute;

			&.det-bottom {
				bottom: 10px;
			}

			&.det-right {
				right: 10px;
			}

			& .detail {
				color: var(--cms-foreground);
				background-color: var(--cms-background);
				border-color: var(--cms-background);
				border-radius: 2px;
				padding: 1px 2px 1px 0px;
				font-size: 16px;
				font-weight: 100;
				white-space: pre;
				margin-left: 3px;
				margin-top: 3px;
				float: right;
			}
		}
	}

	& .card-body {
		width: 100%;

		color: var(--cms-card-foreground, var(--cms-foreground));
		background-color: var(--cms-card-background, var(--cms-background));

		padding: 1rem;

		& .card-text {
			color: var(--cms-card-foreground, var(--cms-foreground));
			padding-top: 3px;
			padding-bottom: 3px;
			& .set-title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
			}
			& a {
				color: var(--cms-card-title-link-foreground, var(--cms-card-links-foreground, var(--cms-theme-color)));
			}
		}

		& .card-subtitle {
			display: flex;
			align-items: center;
			gap: 10px;
			padding-top: 3px;
			padding-bottom: 3px;
			color: var(--cms-card-subtitle-foreground, var(--cms-card-foreground, var(--cms-foreground)));
		}

		& .models {
			& i {
				color: silver;
			}
			& a {
				color: var(--cms-card-links-foreground, var(--cms-theme-color));
			}
		}
		& .stats {
			padding-top: 3px;
			padding-bottom: 3px;
			display: flex;
			align-items: center;
			

			& .thumbsup {
				white-space: nowrap;
				& img {
					max-height: 15px;
				}
			}
		}

		& .date {
			margin-right: 5px;
			font-style: italic;
			color: var(--cms-card-date-foreground, var(--cms-card-foreground, #b8b8b8));
		}

		& .data-type-title, & .data-type-sep {
			// margin-right: 5px;
			font-style: italic;
			font-size: 12px;
			color: var(--cms-card-date-foreground, var(--cms-card-foreground, #b8b8b8));
		}
		& .data-type-values .data-type-value:first-of-type {
			margin-left:5px;
		}

		& .description {
			color: var(--cms-card-description-foreground, var(--cms-card-foreground, var(--cms-foreground)));
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4; /* number of lines to show */
			line-height: var(--bs-body-line-height);        /* fallback */
			max-height: calc(var(--bs-body-line-height) * 2);       /* fallback */
			margin-top: 3px;
		}

		& ng-icon {
			width: 16px;
			height: 16px;
			margin-right: 0px;
			padding-top: 0px;
		}

	}
}
