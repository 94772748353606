/*
@media (prefers-color-scheme: dark) {
@media (prefers-color-scheme: light) {
  :root {
    --background-color: darkslategray;
    --foreground-color: mintcream;
  }
}
*/

:root {
	--cms-consent-modal-background: var(--cms-background);
	--cms-consent-modal-foreground: var(--cms-foreground);
}

html {
	scroll-behavior: auto!important;
}

body {
	font-family: 'Roboto Condensed', sans-serif;
	background-color: var(--cms-page-background);
	color: var(--cms-page-foreground);
}

/* BS Overrides */
.row > * {
	// padding-right: calc(var(--bs-gutter-x) * .25);
	// padding-left: calc(var(--bs-gutter-x) * .25);
}
@media (min-width: 1400px) {
    .container {
        max-width: 1520px;
    }
}




.nav-link {
	color: var(--cms-navbar-foreground, var(--cms-theme-color));
	&.dropdown-toggle {
		background-color: transparent;
		border: none;
		display: flex;
		align-items: center;
	}
	.navbar-nav & {
		--bs-navbar-nav-link-padding-x: 15px;
		font-size: 1.3em;
		padding-right: 15px;
		padding-left: 15px;
		color: var(--cms-navbar-foreground, var(--cms-foreground));
	}
	.navbar-nav .show > &, .navbar-nav &.active{
		color: var(--cms-navbar-foreground, var(--cms-theme-color));
	}

	svg {
		fill: var(--cms-navbar-foreground, var(--cms-foreground));
		transition: fill 0.15s ease-in-out;
	}

	&:active {
		color: var(--cms-links-primary, var(--cms-theme-color));
	}
	&.active, &:active {
		color: var(--cms-links-primary-active, var(--cms-theme-color))
	}
	&:hover {
		color: var(--cms-links-primary-hover, var(--cms-theme-color));

		svg {
			fill: var(--cms-links-primary-hover, var(--cms-theme-color));
		}
	}
}
.nav-tabs {
    border-bottom: var(--bs-nav-tabs-border-width) solid var(--cms-background);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: var(--cms-navbar-foreground);
	background-color: var(--cms-background);
    border-color: var(--cms-background);
}
.nav-item.dropdown .dropdown-menu {
	--bs-dropdown-min-width: 200px;
	& .nav-link {
		font-size: 0.95rem;
	}
	&.nav-menu {
		background-color: var(--cms-navbar-background, var(--cms-background));
	}
}
.btn {
	--bs-btn-color: var(--cms-button-default-foreground, var(--cms-foreground));
	--bs-btn-bg: var(--cms-button-default-background, var(--cms-background));
    --bs-btn-hover-color: var(--cms-button-default-foreground-hover);
    --bs-btn-hover-bg: var(--cms-button-default-background-hover);
	&.btn-link {
		--bs-btn-hover-bg: transparent;
	}
}
.btn-link {
	color: var(--cms-links-primary, var(--cms-theme-color));
	text-decoration: none;
	&:hover {
		color: var(--cms-links-primary-hover, var(--cms-theme-color));
	}
}
.btn-default {
	--bs-btn-color: var(--cms-button-default-foreground, var(--cms-button-default-foreground, var(--cms-page-foreground)));
	--bs-btn-bg: var(--cms-button-default-background, var(--cms-button-default-background, var(--cms-background)));
	--bs-btn-border-color: var(--cms-button-default-background, var(--cms-button-default-background, var(--cms-background)));
	--bs-btn-hover-color: var(--cms-button-default-foreground-hover, var(--cms-button-default-foreground, var(--cms-page-foreground)));
	--bs-btn-hover-bg: var(--cms-button-default-background-hover, var(--cms-button-default-background, var(--cms-background)));
	--bs-btn-hover-border-color: var(--cms-button-default-background-hover, var(--cms-button-default-background, var(--cms-background)));
	--bs-btn-active-color: var(--cms-button-default-foreground-hover, var(--cms-button-default-foreground, var(--cms-page-foreground)));
	--bs-btn-active-bg: var(--cms-button-default-background-hover, var(--cms-button-default-background, var(--cms-background)));
	--bs-btn-active-border-color: var(--cms-button-default-background-hover, var(--cms-button-default-background, var(--cms-background)));
}
.btn-primary {
	--bs-btn-color: var(--cms-button-primary-foreground, var(--cms-button-default-foreground, var(--cms-page-foreground)));
	--bs-btn-bg: var(--cms-button-primary-background, var(--cms-button-default-background, var(--cms-theme-color)));
	--bs-btn-border-color: var(--cms-button-primary-background, var(--cms-button-default-background, var(--cms-theme-color)));
	--bs-btn-hover-color: var(--cms-button-primary-foreground-hover, var(--cms-button-default-foreground, var(--cms-page-foreground)));
	--bs-btn-hover-bg: var(--cms-button-primary-background-hover, var(--cms-button-default-background, var(--cms-theme-color)));
	--bs-btn-hover-border-color: var(--cms-button-primary-background-hover, var(--cms-button-default-background, var(--cms-theme-color)));
	--bs-btn-active-color: var(--cms-button-primary-foreground-hover, var(--cms-button-default-foreground, var(--cms-page-foreground)));
	--bs-btn-active-bg: var(--cms-button-primary-background-hover, var(--cms-button-default-background, var(--cms-theme-color)));
	--bs-btn-active-border-color: var(--cms-button-primary-background-hover, var(--cms-button-default-background, var(--cms-theme-color)));
}



/* Base Color Classes CLasses */
/*.cms-body-bg {}*/
/*.cms-text-primary {}*/
/*.cms-links-primary {}*/
.cms-bg-nav 				{ background-color:var(--cms-navbar-background, var(--cms-background)); }
.cms-bg-nav-transparent		{ background-color:transparent; }
.cms-button-bg 				{ background-color:var(--cms-button-bg); color:var(--cms-button-text); }
.cms-button-bg-active 		{ background-color:var(--cms-button-bg-active); color:var(--cms-button-text); }



/* Defaults */
/* .cms-link does not need styling in base theme */
a {
	display: inline-block;
	color: var(--cms-links-primary, var(--cms-theme-color));
	text-decoration: none;
	pointer-events: initial;
	&:hover {
		color: var(--cms-links-primary-hover, var(--cms-theme-color));
	}
	&:active {
		color: var(--cms-links-primary-active, var(--cms-theme-color));
	}
}
h3, .h3 {
	margin-bottom: .5rem;
	font-weight: 500;
	line-height: 1.2;
	// font-size: 2.5rem;
}
.text-size-sub {
	font-size: 14px;
}
.view-all-link {
	font-size: 1.25rem;
	padding-left:15px;
	&:hover {
		color: var(--cms-links-primary-hover, var(--cms-theme-color));
	}
	&.link-b {
		border-radius: 5px;
		// color:  var(--cms-links-primary, var(--cms-theme-color));
		margin-top:10px;
		display: block;
		font-weight: var(--bs-nav-link-font-weight);
		text-decoration: none;
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
	}
}

.cp { cursor: pointer; }
.section {
    background-color: transparent;
}


/* Utilities */
.btn-no-border {
	outline: none !important;
	border: 0px !important;
}

.thumbsize-short { height:150px; }
.thumbsize-medium { height:200px; }
.thumbsize-tall { height:250px; }
.thumbsize-extratall { height:300px; }
.thumbsize-ratio_219 { aspect-ratio: 21 / 9; }
.thumbsize-ratio_169 { aspect-ratio: 16 / 9; }
.thumbsize-ratio_32  { aspect-ratio: 3 / 2;  }
.thumbsize-ratio_11  { aspect-ratio: 1 / 1;  }
.thumbsize-ratio_23  { aspect-ratio: 2 / 3;  }
.thumbsize-ratio_916 { aspect-ratio: 9 / 16; }
.thumbsize-ratio_921 { aspect-ratio: 9 / 21; }
.text-sm { font-size: 0.80em; }
.text-lg { font-size: 1.25em; }
.text-xl { font-size: 1.55em; }
@media (min-width: 992px) {
	.thumbsize-short { height:150px; }
	.thumbsize-medium { height:225px; }
	.thumbsize-tall { height:300px; }
	.thumbsize-extratall { height:425px; }
	.text-sm { font-size: 0.80em; }
	.text-lg { font-size: 1.5em; }
	.text-xl { font-size: 2.0em; }
}
.btn-set-view-switch {
	background-color: var(--cms-background);
	border-color: var(--cms-background);
}
.btn-set-view-switch.active, .btn-set-view-switch.active:hover {
	background-color: var(--cms-theme-color);
	border-color: var(--cms-theme-color);
}
.btn-gray {
	background: #e7e7e724;
	color: var(--cms-page-foreground);
	padding: 5px 10px;
	border-radius: 4px;
	display: flex;
	align-items: center;
}
.btn-social {
	border-color:transparent;
	background: transparent;
	color: var(--cms-page-foreground);
	display: flex;
	align-items: center;
	--bs-btn-padding-x: 0.25rem;
	.set-view &, .view & {
		background-color: var(--cms-social-btn-background, transparent);
		border-color: var(--cms-social-btn-border-color, transparent);
		color: var(--cms-social-btn-foreground, var(--cms-foreground));
	}
	&.active,
	&.selected {
		ng-icon {
			color: var(--cms-social-icon-active-foreground, var(--cms-theme-color));
		}
	}
}

/*
.components-wrapper.container-fluid .cms-block-full-width .container-fluid
*/
.components-wrapper {
	&.container {
		& .cms-block-full-width {
			position: relative;
			margin-left: calc(-50vw + 50%);
			margin-right: calc(-50vw + 50%);
			& .container-fluid {
				padding-right: 30px;
				padding-left: 30px;
				margin-right: 0;
				margin-left: 0;
			}
			&.carousel {
				& .container-fluid {
					padding-right: 5px;
					padding-left: 5px;
				}
			}
		}
	}
	&.container-fluid {
		--bs-gutter-x: 0;
		& .cms-block-full-width {
			position: relative;
			width: calc(100vw - (100vw - 100%));
			& .container-fluid {
				padding-right: 20px;
				padding-left: 20px;
				margin-right: 0;
				margin-left: 0;
			}
			&.carousel {
				& .container-fluid {
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
}

.cms-footer-list {
	h3 {
		font-size:0.9em;
	}
}

.cms-navigation-list {
	& h3 {
		font-size:1.2rem;
	}
}



/* Plugin Overrides */
ng-icon {
	margin-top: 0;
	font-size: 14px;
	.btn-social & {
		color: var(--cms-social-icon-foreground, #888888);
	}
}


/* HH Theme - Special Designs */
body { overflow-x: hidden; }

.pagination {
    justify-content: center !important;
    margin-top: 15px;
	& .page-link {
		color: var(--cms-pagination-link-foreground, var(--cms-foreground));
		background-color: var(--cms-pagination-link-background, var(--cms-background));
		border-color: var(--cms-pagination-link-border-color, var(--cms-background));
		margin: 0 10px;
		font-size: 1.25em;
	}
	& .page-link.disabled, & .disabled > .page-link {
		opacity: 75%;
		color: var(--cms-pagination-link-disabled-foreground, var(--cms-foreground));
		background-color: var(--cms-pagination-link-disabled-background, var(--cms-page-background));
		border-color: var(--cms-pagination-link-disabled-border-color, var(--cms-background));
	}
	& .page-link.active, & .active > .page-link {
		color: var(--cms-pagination-link-active-foreground, var(--cms-foreground));
		background-color: var(--cms-pagination-link-active-background, var(--cms-theme-color));
		border-color: var(--cms-pagination-link-active-border-color, var(--cms-theme-color));
	}
}
.page-item.active .page-link {
	z-index: auto!important;
}
.details-list {
	padding: 20px;
	display: block;
	color: var(--cms-details-list-foreground, var(--cms-foreground));
	background-color: var(--cms-details-list-background, var(--cms-background));
	font-size: 0.9em;
	&.set {
		margin-top: 2em;
	}
	@media (max-width: 992px) {
		margin-top:30px;
	}
	& .data-type-title {
		text-align: right;
	}
	& .data-types-container .data-value-link {
		color: var(--cms-details-list-links-primary, var(--cms-links-primary, var(--cms-theme-color)));
	}
	& .data-types-container.model {
		& .data-type-title {
			padding-right: 5px;
		}
		& .data-type-values {
			padding-left: 5px;
		}
		@media screen and (max-width:575px) {
			& .data-type-title {
				width:45%;
			}
			& .data-type-values {
				width:55%;
			}
		}
	}
	& .data-types-container.set {
		// margin-top: 2em;
		margin-bottom: 0;

		& .data-type-title {
			display: inline;
			width: unset;
			text-align: left;
			margin-right: 10px;
		}
		& .data-type-values {
			display:inline;
			width: unset;
		}

		&.complex {
			& .data-type-values {
				@media (max-width: 992px) {
					display:inline!important;
					width: unset;
				}
			}
			& .data-type-title {
				@media (max-width: 992px) {
					display:inline!important;
					width: unset;
				}
				@media (min-width: 992px) {
					text-align: right;
				}
			}
		}
	}
	& dd:last-child {
	  margin-bottom: 0;
	}
}
.change-username {
	color: #c0d2df;
	background-color: #212529;
	border-color: #415060;
	padding:15px 35px;
	margin: 0 20px;
	margin-top: 0px;
}

// View blocks
.view {
	& .description {
		line-height: 2rem;
	}
	& .properties {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		& .published { }
		& .social { }
	}
	& .details {
		padding: 20px;
	}
	& .title {
		padding-left: 20px;
	}
}

/* Inputs */
.form-control {
	background-color: var(--cms-input-background, #383838);
	border: 1px solid var(--cms-input-background, #383838);
	color: var(--cms-input-foreground, #ffffff);
}

.form-control:focus {
	border-color: var(--cms-input-background, #383838);
	box-shadow: 0 0 0 0.25rem var(--cms-input-background, #38383850);
}


/* HH Theme Enhancers */
.cms-nav-extrapadd, .cms-nav-extrapadd { padding: 40px 50px!important; }
.cms-nav-condensed, .cms-nav-condensed .min-h-60px { min-height: auto!important; }
.cms-nav-condensed .nav-link { font-size:12px; }

.cms-offcanvas-menu {
	// background: #595959;
	background-color: var(--cms-navbar-offcanvas-background, var(--cms-navbar-background, var(--cms-background)));
	border-color: var(--cms-navbar-offcanvas-background, var(--cms-navbar-background, var(--cms-background)));
	& .btn-close {
		background-color: var(--cms-navbar-offcanvas-foreground, var(--cms-navbar-foreground, var(--cms-foreground)));
	}
}

/* Helper Classes */
.sticky-block-bottom, .sticky-block-top {
    position: sticky;
    z-index: 1001;
}
.sticky-block-bottom {
    bottom: 0;
}
.sticky-block-top {
    top: 0;
}

