.text-loading-skeleton {
  display: inline-block;

  width: 100px;
  height: 24px;

  background: #9898983b;
  animation: loading-skeleton 1s infinite alternate;
  border-radius: 5px;
}

@keyframes loading-skeleton {
  from {
    opacity: .4;
  }
  to {
    opacity: 1;
  }
}
