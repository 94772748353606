$primary: #014474;

// PAGINATION

$pagination-disabled-color: #424141;
$pagination-disabled-bg: #0e0e0e;
$pagination-disabled-border-color: #404040;

$pagination-color: #0075c9;
$pagination-bg: #0e0e0e;
$pagination-border-color: #404040;

$pagination-hover-color: white;
$pagination-hover-bg: #005b9c;
$pagination-hover-border-color: #005b9c;

